document.addEventListener("DOMContentLoaded", () => {
	if (typeof globalThis.__moe === "undefined") {
		throw new Error("Failed to intialise")
	}
	const { nav, navIndicator, navSet, prevPage } = globalThis.__moe

	if (nav && navIndicator) {
		const children = Array.from(nav.children)
		const current = children.find((el) => el.hasAttribute("aria-current"))
		const handleIndicator = () => {
			if (current) {
				if (prevPage === "null") {
					navIndicator.setAttribute("data-fade", "in")
				}
				navSet(current.id)
			} else {
				if (prevPage) {
					navIndicator.setAttribute("data-fade", "out")
					setTimeout(() => navSet(null), 150)
				}
				navIndicator.setAttribute("data-hidden", "")
			}
		}
		const resizeObserver = new ResizeObserver(handleIndicator)
		const handleUnload = () => current ? sessionStorage.setItem("page", current.id) : sessionStorage.setItem("page", "null")

		handleIndicator()

		resizeObserver.observe(nav)

		window.addEventListener("beforeunload", handleUnload)
		window.addEventListener("beforeunload", () => resizeObserver.disconnect())
	}
})
